.card__container {
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  /* min-height: 80vh; */
  padding: 2rem;
  margin: 10px 50px 25px 50px;
  margin: auto;
}

.left-header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin: auto;
  align-items: center;
  margin-left: 40px;
  background-color: white;
}

.navlink {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.navlink1 {
  font-weight: 600;
  cursor: pointer;
  color: black;
  font-size: 1rem
}

.navlink1:hover {
  color: #ECA020 !important
}

.navlink:hover {
  color: #000;
}

.right-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  background-color: #ECA020;
  align-items: center;
  height: 70px;
  color: #F1F1F1;
}

.drwerBtn {
  width: 100%;
  text-align: end;
  display: none;
}

.headerShow_hide {
  display: block;
}

.download-button {
  border-radius: 0.313rem;
  color: black;
  width: 25%;
  height: 40px;
  font-weight: 600;
  padding: auto;
  background-color: white;
}

.download-button:hover {
  color: #ECA020;
}

.left-side {
  display: grid;
  place-items: center;
  margin-top: 60px;
}

.left-side-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.heading-text {
  color: black;
  font-size: 2rem;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}

.heading-subtext {
  font-family: Poppins, solid;
  font-weight: 900;

}

.article-text {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #000000;
  text-align: justify;

}

.search-tab {
  color: #000;
  background-color: #FFF2DC;
  border: none;
  padding: 15px;
  width: 50%;
  border-radius: 9px;
  margin-top: 10px;
  outline: none;
}

.search-button {
  border-radius: 9px;
  color: white;
  text-transform: uppercase;
  background: linear-gradient(to bottom, #F27921, #ECA020);
  margin-top: 10px;
}

.search-button:hover {
  background: linear-gradient(to bottom, #ECA020, #F27921);
  color: #000;
}

.right-side {
  display: grid;
  min-height: 50vh;
  place-items: center;
  /* width: 100%; */
  background-color: #ECA020;

  margin: auto;
  padding-top: 100px;
}

.image-rightside {
  width: 80%;
  margin-top: -60%;
  /* margin-left: 10px; */

}

.background-photo {
  background-image: url('../assest/Group\ 33032.svg');
  /* height: 80vh; */
  max-height: max-content;
  margin: 70px;
  background-position: center;
  padding-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.para-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}


.widthProp {
  width: 45%;
}

.right-side-text {
  display: grid;
  place-items: center;
  margin-top: 20px;
}

.right-side-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  text-align: justify;
}

.rounder-image {
  width: 60%;
  border-radius: 50;
  margin: auto;
}

.circle {
  border: 2px solid #ECA020;
  border-radius: 50%;
  position: absolute;
  width: 61%;
  height: 100%;
  top: 0;
  left: 19%;
}

.circle2 {
  border: 2px solid #F27921;
  border-radius: 50%;
  position: absolute;
  width: 58%;
  height: 100%;
  top: 0;
  left: 18%;
}

.service {
  background-color: #F1F1F1;
  border-radius: 5px;
  /* width: 70%; */
  /* padding: auto; */
  height: 100%;
}

.contentPadding {
  padding: 12px;
  text-align: justify;
  align-items: center;
  margin-right: 5px;
}

.car-container {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
  min-height: 40vh;
  padding: 2rem;
  margin: 10px 50px 25px 50px;

  background-color: white;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.footer-back-img {
  background-image: url('../assest/Group\ 33032.svg');
  max-height: max-content;
  margin: auto;
  background-position: center;
  padding: 70px;
  background-size: cover;
  background-repeat: no-repeat
}

.footer-article-text {
  display: flex;
  margin: 10px 60px;
  align-items: center;


}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px;
  gap: 5px;
}

.black_line {
  border-right: 2px solid grey;
  width: 3%;
  height: 50px;
}

.getTouchParent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.emailBox {
  display: flex;
  align-items: center;
  gap: 15px;
}

.testimonialBorderDown {
  border-radius: 10px;
  height: 5px;
  background: linear-gradient(to left, #ECA020, #F27921);
}

.slider_content {
  background: linear-gradient(to left, #ECA020, #F27921);
  color: white;
  /* height: 300px; */
  width: 31%;
  text-align: center;
  border-radius: 32px;
  position: relative;
  padding: 10px;
}

.mt-2 {
  margin-top: 1.3rem;
}

.cardClass {
  width: 80%;
  background-color: rgb(241, 241, 241);
  border-radius: 18px;
}

.solidAppLink {
  color: black;
  text-decoration: none;
}

.solidAppLink:hover {
  text-decoration: underline;
}