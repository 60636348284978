@media screen and (max-width:992px) {
    .drwerBtn {
        display: block;
    }

    .headerShow_hide {
        display: none;
    }

    .right-header {
        display: none;
    }

    .left-side {
        display: grid;
        place-items: center;
        margin-bottom: 60px;
        margin-top: 0;
    }
    .background-photo {
        margin: 20px;
    }
    .cardClass{
        width: 100%;
    }
    .black_line{
        display: none;
    }
}